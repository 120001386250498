import React from 'react';
import {
  Box,
  Typography,
  ListItemIcon,
  ListItem,
  ListItemText,
  Stack,
  useMediaQuery,
  List
} from '@mui/material';
import { useTheme } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SignUpReasons = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderCircleIcon = () => {
    return (
      <ListItemIcon
        sx={{
          display: 'flex',
          minWidth: isMobile ? '36px' : '56px'
          // display: {
          //   xs: 'block',
          //   sm: 'flex'
          // }
        }}
      >
        <CheckCircleIcon
          color="success"
          fontSize={isMobile ? 'small' : 'large'}
          sx={{
            marginTop: '12px'
          }}
        />
      </ListItemIcon>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '600px',
        margin: '0px auto 0px auto'
      }}
    >
      <Stack spacing={2}>
        <List>
          <ListItem sx={{ alignItems: 'flex-start' }}>
            {renderCircleIcon()}
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: 'text.primary' }}
                >
                  Get notified FIRST of Amazon&apos;s BEST deals!
                </Typography>
              }
              secondary="Be ahead of the crowd and grab the hottest deals before they're gone."
            />
          </ListItem>
          <ListItem sx={{ alignItems: 'flex-start' }}>
            {renderCircleIcon()}
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: 'text.primary' }}
                >
                  Find deals on everyday essentials.
                </Typography>
              }
              secondary="Save big on household items, diapers, kids' toys, and more that you'll actually use."
            />
          </ListItem>
          <ListItem sx={{ alignItems: 'flex-start' }}>
            {renderCircleIcon()}
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: 'text.primary' }}
                >
                  Join a trusted community of savvy shoppers.
                </Typography>
              }
              secondary="Thousands of subscribers and an incredibly low unsubscribe rate say it all!"
            />
          </ListItem>
        </List>
      </Stack>
    </Box>
  );
};

export default SignUpReasons;
