import React from 'react';

import { Alert } from '@mui/material';

const SuspensionMessage = () => {
  return (
    <Alert
      severity="error"
      sx={{
        margin: '1rem'
      }}
    >
      Your account has been suspended. Please contact us at
      suspensions@jungle.deals if you believe this is an error.
    </Alert>
  );
};

export default SuspensionMessage;
