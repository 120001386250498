import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import {
  Box,
  FormGroup,
  Typography,
  TextField,
  Checkbox,
  Button,
  ListItem,
  ListItemText,
  List,
  Grid,
  Link,
  FormControlLabel,
  Alert
} from '@mui/material';
import { useTheme } from '@mui/system';
import ReCAPTCHA from 'react-google-recaptcha';
import PageGrid from '@components/PageGrid';
import config from '@configFile';
import { useSignin } from '@hooks/useSignin';
import trackUse from '@utils/trackUse';
import { useGetUserData } from '@hooks/useGetUserData';
import { useEmailSignUp } from '@hooks/useEmail';
import { useVerifyRecapture, RecaptchaResponse } from '@hooks/useRecaptcha';
import EmailListUI from '@components/EmailLists/components/EmailListUI';
import Loading from '@components/Loading';
import PageContainer from '@components/PageContainer';
import { useSignup } from '@pages/Signup/useSignup';
import SuspensionMessage from '@pages/Email/components/SuspensionMessage';
import {
  isValidEmail,
  getParamsFromURLSearch,
  isNameProhibited
} from '@utils/index';
import mailingLists from '@utils/mailingLists';
import { sanitizeEmail } from '@utils/securityUtils';
import useLocalStorage from '@hooks/useLocalStorage';
import LastFiveMailings from '@components/Last5Mailings';
import SignUpReasons from '@pages/Email/components/SignUpReasons';
import AddedToEmailListsMessage from './components/AddedToEmailListsMessage';
import LoadingModal from './components/LoadingModal';

// Todo: Fix password requirements
interface EmailProps {
  showPageContainer?: boolean;
  showReasons?: boolean;
  signUpLocation?: string;
}

const Email = ({
  showPageContainer = true,
  showReasons = true,
  signUpLocation = 'page'
}: EmailProps) => {
  const location = useLocation();
  const { search } = location;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasSignedUpForMailingLists, setHasSignedUpForMailingLists] =
    useLocalStorage(config.HAS_SIGNED_UP_FOR_MAILING_LISTS, false);
  const defaultEmail = getParamsFromURLSearch(search)?.email || '';
  const defaultFirstName = getParamsFromURLSearch(search)?.firstName || '';
  const defaultLastName = getParamsFromURLSearch(search)?.lastName || '';
  const { mutate: signin } = useSignin();

  const [email, setEmail] = useState(defaultEmail);
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [canGetAccount, setCanGetAccount] = useState<boolean>(true);
  const [hasTouchedPasswordField, setHasTouchedPasswordField] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    mailingLists
      .filter((ml) => ml.defaultCheckedForSignUp)
      .map((emailList) => emailList.id)
  );
  const recaptcha = useRef<ReCAPTCHA>(null);
  const { mutate: verifyRecaptcha } = useVerifyRecapture();
  const { mutate: signUpForMailingLists, isLoading: isSigningUp } =
    useEmailSignUp();
  const { mutate: signup } = useSignup();
  const { data: user, isLoading: isLoadingUser } = useGetUserData();
  const eligibleForAccount = !hasAccount && canGetAccount;

  const onChange = (value: string) => {
    verifyRecaptcha(value, {
      onSuccess: (data: RecaptchaResponse) => {
        if (data.success) {
          setHasVerified(true);
        } else {
          // eslint-disable-next-line no-alert
          alert('Please complete the captcha to verify you are not a robot');
        }
      },
      onError: () => {
        // eslint-disable-next-line no-alert
        alert('Please try again later');
      }
    });
  };

  const getAccount = () => {
    const account = {
      firstName: firstName.trim(),
      email: email.trim(),
      lastName: lastName.trim(),
      password: password.trim()
    };

    // get the account if it exists
    signup(account, {
      onError: () => {
        // eslint-disable-next-line no-alert
        alert('There was an error signing up, please try again later');
      },
      onSuccess: () => {
        setHasAccount(true);
      }
    });
  };

  const submitSignup = () => {
    signUpForMailingLists(
      {
        email,
        selectedEmails,
        location: signUpLocation
      },
      {
        onSuccess: (data) => {
          setHasSubmitted(true);
          setHasAccount(!!data.hasAccount);
          setCanGetAccount(!!data.canGetAccount);
          setHasSignedUpForMailingLists(true);
        },
        onError: () => {
          // eslint-disable-next-line no-alert
          alert('There was an error signing up, please try again later');
        }
      }
    );
  };

  const handleClickCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    mailingListId: string
  ) => {
    e.preventDefault();
    const updatedEmails = e.target.checked
      ? [...selectedEmails, mailingListId]
      : selectedEmails.filter((id) => id !== mailingListId);
    setSelectedEmails(updatedEmails);
  };

  const handleClickListRow = (
    e: React.MouseEvent<HTMLLIElement>,
    mailingListId: string
  ) => {
    e.preventDefault();

    const isNotCurrentlyChecked = !selectedEmails.includes(mailingListId);

    const updatedEmails = isNotCurrentlyChecked
      ? [...selectedEmails, mailingListId]
      : selectedEmails.filter((id) => id !== mailingListId);
    setSelectedEmails(updatedEmails);
  };

  const displayAccountSuspended = () => {
    if (hasLoggedIn) {
      if (hasError) {
        return <SuspensionMessage />;
      }

      return (
        <Alert
          severity="success"
          sx={{
            margin: '1rem'
          }}
        >
          You have successfully signed up!
        </Alert>
      );
    }

    return null;
  };

  const successFunction = (data: {
    isSuspended: boolean;
    hasAccount: boolean;
  }) => {
    const { isSuspended } = data;
    // refresh the page in half a second
    // if (refreshOnLogin) {
    setTimeout(() => {
      window.location.reload();
    }, 500);
    // }

    if (isSuspended) {
      console.log('account suspended');
      setHasError(true);
      setHasLoggedIn(true);
    } else {
      console.log('account not suspended');
      setHasLoggedIn(true);
      setHasError(false);
    }
  };

  const signinResponse = {
    onSuccess: successFunction,
    onError: () => {
      console.log('error');
      setHasError(true);
    }
  };

  const renderMainStuff = () => {
    return (
      <Box mb={3}>
        {isSigningUp && <LoadingModal />}
        {isLoadingUser && <Loading />}
        {!isLoadingUser && !user ? (
          <Box sx={{ width: '100%' }}>
            {showReasons ? <SignUpReasons /> : null}
            <Box>
              <FormGroup>
                <List>
                  {mailingLists.map((ml) => {
                    return (
                      <ListItem
                        key={ml.id}
                        onClick={(e) => {
                          handleClickListRow(e, ml.id);
                        }}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'action.hover'
                          },
                          transition: 'background-color 0.3s'
                        }}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            checked={selectedEmails.includes(ml.id)}
                            onChange={(e) => handleClickCheckbox(e, ml.id)}
                          />
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              {ml.displayName}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {ml.displayDescription}
                              </Typography>
                              {ml.showLast5Mailings ? (
                                <LastFiveMailings mailingListId={ml.id} />
                              ) : null}
                            </>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    margin: {
                      xs: '8px 8px 0 8px',
                      sm: '16px 0 0 0'
                    },
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: '400px',
                      width: '100%',
                      mb: 1
                    }}
                  >
                    <TextField
                      label="Email"
                      size="small"
                      name="email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={email}
                      onChange={(e) => {
                        setEmail(sanitizeEmail(e.target.value));
                      }}
                      sx={{
                        margin: '0'
                      }}
                    />
                  </Box>
                </Box>
              </FormGroup>
              <Box
                sx={{
                  margin: {
                    xs: '16px 8px 0 8px'
                  },
                  textAlign: 'center'
                }}
              >
                <Typography
                  variant="caption"
                  align="center"
                  textAlign="center"
                  sx={{
                    color: isDarkMode ? '#bbb' : '#333',
                    fontSize: '0.875rem'
                  }}
                >
                  Will be used in accordance with our{' '}
                  <Link
                    href="https://www.jungle.deals/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: isDarkMode ? '#b397ee' : '#3b5998' }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Box>
              <br />
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={onChange}
                style={{
                  margin: '0px auto',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              />
              <br />

              <Box display="flex" justifyContent="center" mb={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitSignup}
                  size="large"
                  disabled={
                    !isValidEmail(email) ||
                    !hasVerified ||
                    selectedEmails.length === 0
                  }
                  sx={{
                    mt: 1,
                    borderRadius: '24px',
                    paddingX: 3
                  }}
                >
                  Sign me up
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
        {!isLoadingUser && user ? <EmailListUI /> : null}
      </Box>
    );
  };

  if (hasSubmitted && eligibleForAccount) {
    // let people know that they can give us a password and we can easily make them an account
    return (
      <PageContainer
        title="Set up a new account"
        fullWidth
        showContainer={showPageContainer}
      >
        <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          <Typography variant="body1" gutterBottom>
            You&apos;re all set up! You should be receiving your first email
            soon!
          </Typography>
          <div>
            <Typography variant="body1">
              It looks like you don&apos;t have an account with us yet,
              it&apos;s free and unlocks premium features - including direct
              access to your mailing list settings. If you would like to make an
              account, please enter your details below.
            </Typography>
            {hasError ? (
              <Box
                sx={{
                  margin: '0 auto 1rem auto',
                  maxWidth: '400px'
                }}
              >
                <Alert severity="error">
                  Invalid email or name. Try to register with a different email.
                </Alert>
              </Box>
            ) : null}
            <Box margin="16px auto">
              {displayAccountSuspended()}
              <GoogleLogin
                text="signup_with"
                onSuccess={(credentialResponse: { credential: string }) => {
                  signin(
                    {
                      ...credentialResponse,
                      isSignUp: true
                    },
                    signinResponse
                  );
                }}
                onError={() => {
                  trackUse({
                    item: 'signup-fail-click',
                    value: `signup-fail-click`,
                    type: 'ERROR'
                  });
                }}
                useOneTap
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: '12px'
              }}
            >
              <Grid item xs={12}>
                <Box display="flex" margin="0 auto" justifyContent="center">
                  <TextField
                    label="First Name"
                    size="small"
                    name="firstName"
                    autoComplete="given-name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    sx={{
                      margin: '0',
                      maxWidth: '350px'
                    }}
                    error={isNameProhibited(firstName.trim(), lastName.trim())}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" margin="0 auto" justifyContent="center">
                  <TextField
                    label="Last Name"
                    size="small"
                    variant="outlined"
                    name="lastName"
                    autoComplete="family-name"
                    fullWidth
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    sx={{
                      margin: '0',
                      maxWidth: '350px'
                    }}
                    error={isNameProhibited(firstName.trim(), lastName.trim())}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" margin="0 auto" justifyContent="center">
                  <TextField
                    label="Password"
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    onBlur={() => setHasTouchedPasswordField(true)}
                    error={password.length < 12 && hasTouchedPasswordField}
                    style={{
                      margin: '8px 0',
                      maxWidth: '350px'
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
            <FormControlLabel
              sx={{ mb: '12px' }}
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={() => {
                    setTermsChecked(!termsChecked);
                  }}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <span>
                  I have read and I accept the{' '}
                  <Link href="/privacy-policy" target="_blank" rel="noopener">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link href="/terms" target="_blank" rel="noopener">
                    Terms of Service
                  </Link>
                  .
                </span>
              }
            />
            <br />
            <Box
              display="flex"
              justifyContent="end"
              sx={{
                marginTop: '16px'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={getAccount}
                disabled={
                  password.length < 12 ||
                  !termsChecked ||
                  isNameProhibited(firstName.trim(), lastName.trim()) ||
                  firstName.trim().length < 2 ||
                  lastName.trim().length < 2
                }
              >
                Sign up
              </Button>
            </Box>
          </div>
        </Box>
      </PageContainer>
    );
  }

  if (hasSubmitted) {
    return <AddedToEmailListsMessage showPageContainer={showPageContainer} />;
  }

  if (!showPageContainer) {
    return renderMainStuff();
  }

  return (
    <PageGrid>
      <PageContainer title="Sign up for our email lists" fullWidth>
        <Box
          sx={{
            maxWidth: '900px',
            textAlign: 'center',
            margin: '0 auto'
          }}
        >
          {renderMainStuff()}
        </Box>
      </PageContainer>
    </PageGrid>
  );
};

export default Email;
